<template>
  <div class="page">
    <div class="page_title">{{ $t('aboutPage.title') }}</div>
    <div class="content">
      <!-- <p>
        EasyFund is a financial technology company aimed at Nigeria market, mainly providing accessible financial services to people in Nigeria. Our goal is to provide alternative financial services to our customers. Our mission is to see make sure that all our customers are treated right, making sure that we gain every customer loyalty.
      </p>
      <p>
        Our vision is to be among the top five best loan app globally providing outstanding and quality, service and making impacts in our customer lives.
      </p>
      <p>
        EasyFund is an online loan APP under our company, through our EasyFund APP, we can achieve a relaxed loan application process, EasyFund can meet your financial needs and help you to maintain a financial stability. Just download our app and follow the procedure. Then proceed to upload a valid ID card either your NIN, International passport or voters card issued by the Nigeria government and then fill in the application form to start. Within a few minutes, we can communicate our loan decision through our application, and your loan can be transferred immediately to your preferred method of withdrawal.
      </p> -->
      <p>Papichou is a financial technology company that seeks to provide accessible financial services to people in Nigeria. </p>
      <p>VISION</p>
      <p>Our vision is to be one of the top 5 best online loan apps in the world, providing outstanding quality services, and making a significant impact in the lives of our customers.</p>
      <p>MISSION</p>
      <p>Our mission is to provide our customers with easy access to loans through an effective and efficient route.</p>
      <p>EasyFund is an online loan app registered under Papichou, customers can achieve an effective loan application process through the EasyFund app.</p>
      <p>Papichou  seeks to meet the financial needs of our customers to help maintain financial stability.</p>
      <p>Download our app on playstore and follow the registration procedures, upload a valid ID card, I.e ( International passport, NIN or voters card issued by the Nigerian Government ), then fill in the application form to complete your registration. Within a few minutes, our loan decision will be communicated through the mobile application and the loan will be disbursed immediately to your preferred method of withdrawal.</p>
    </div>
    <div>
      <div class="info">
        <div class="title" >Contact us</div>
        <div class="text">
          <p>For more enquiries about using our services, please contact our customer service team:</p>
          <p>Email: easyfundservice23@gmail.com</p>
          <p>Hotline: 09117302254</p>
          <p>WhatsApp: +2349117302254</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'payment',
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>
@mixin pixel-ratio() {
  @media screen and (-webkit-min-device-pixel-ratio: 1),
  screen and (min--moz-device-pixel-ratio: 1) {
    @import '../assets/scss/sprite/payment.scss';
    .star {
      @include sprite($star);
    }
  }
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2) {
    @import '../assets/scss/sprite/payment@2x.scss';
    .star {
      @include sprite($star-2x);
    }
  }
}

.page {
  @include pixel-ratio();
  text-align:justify;
  text-justify:newspaper;
  width: 60%;
  margin: 0 auto;
  &_title {
    font-size: 30px;
    color: #252525;
  }
  .content {
    margin: 0 auto;
    display: inline-block;
    line-height: 24px;
    > p{
      margin: 10px 0;
    }
  }
  .info {
    display: inline-block;
    margin: 20px auto;
    .title {
      font-size: 30px;
      color: #252525;
    }
    .text {
      display: inline-block;
      color: #252525;
      font-size: 18px;
      > p{
        margin: 10px 0;
      }
    }
  }
}
</style>
